@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
* {
  font-family: "Inter", sans-serif;
}

.font-inter {
  font-family: "Inter", sans-serif !important;
}

.headWord {
  line-height: 57px;
  font-size: 52px;
}

.font-hel {
  font-family: 'Helvetica Neue', sans-serif;
}

.step-item {
  @apply relative flex flex-col justify-center items-center w-72;
}

.step-item:not(:first-child)::before {
  @apply content-[''] bg-gray-300 absolute w-full h-[3px] right-2/4 top-1/3 -translate-y-2/4;
}

.step {
  @apply w-10 h-10 flex items-center justify-center z-10 relative bg-gray-300 border-sky-500 rounded-full font-helvetica-neue font-semibold text-white;
}

.next-btn {
  @apply bg-primary-sky text-gray-100 font-medium px-7 py-1 rounded-lg;
}

.active .step {
  @apply bg-sky-500;
}

.complete .step {
  @apply bg-sky-500;
}

.complete p {
  @apply text-black;
}

.complete:not(:first-child)::before,
.active:not(:first-child)::before {
  @apply bg-sky-500;
}

.stagger-out {
  transition: transform 0.4s ease-out;
  transform: translateX(0);
}

.stagger-out-leave {
  transform: translateX(100%);
}

.service-card {
  width: 350px;
  height: 350px;
}

.gradient-container {
  background: linear-gradient(#97bdf0, #f5f5f5);
}

.question-container {
  background: linear-gradient(to right,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0.5) 70%,
      rgba(0, 0, 0, 0) 100%);
  background-size: 100% 100%;
}

.question-container {
  background: linear-gradient(to right,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0.5) 70%,
      rgba(0, 0, 0, 0) 100%);
  background-size: 100% 100%;
}

.home-multi-bgColor {
  background: linear-gradient(to right,
      rgba(255, 255, 255, 1) 50%,
      rgba(61, 165, 236, 0.15) 85%);
  background-size: 100% 100%;
}

@media (max-width: 768px) {
  .home-multi-bgColor {
    background: linear-gradient(to bottom,
        rgba(255, 255, 255, 1) 50%,
        rgba(61, 165, 236, 0.15) 85%);
    background-size: 100% 100%;
  }
}

.mission-container {
  background: linear-gradient(to right,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0.9) 65%,
      rgba(0, 0, 0, 0) 100%);
  background-size: 100% 100%;
}

.schedule-container {
  background: linear-gradient(to right,
      rgba(61, 165, 236, 1),
      rgba(154, 203, 245, 1));
  background-size: 100% 100%;
}

.money {
  color: #ececb1;
}

.break-inside {
  break-inside: avoid;
  min-width: 0;
}

.bg-custom-image {
  background-image: url("../src/assests/images/spiral.png");
  background-size: cover;
  background-position: center;
}

.select:focus {
  border-color: rgb(3, 19, 3);
}

@media (min-width: 770px) and (max-width: 1024px) {
  .your-selector {
    padding: 0 50px;
  }
}

@media (max-width: 390px) {
  .small-headword {
    width: 300px;
  }
}

@media (max-width: 390px) {
  .calButton {
    width: 280px;
  }
}

@media (max-width: 365px) {
  .calButton {
    width: 260px;
  }
}

@media (max-width: 450px) {
  .image {
    margin: 50px -20px;
  }
}

.custom-upload-field {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 150px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.upload-icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 10px;
}

.upload-text {
  font-size: 14px;
  color: gray;
}

.custom-upload-field input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

textarea {
  resize: none;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 1.5px;
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #6b7280;
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .hidden-sidebar {
    display: none;
  }
}

@media (min-width: 1200px) {
  .show-hamburger {
    display: none;
  }
}

.menu {
  height: 30px;
  width: 30px;
  cursor: pointer;
  /* background-color: aqua; */
}

.bar1 {
  width: 100%;
  height: 2px;
  background-color: #333;
  transform: translateY(8px);
}

.bar2 {
  width: 100%;
  height: 2px;
  background-color: #333;
  transform: translateY(16px);
}

.bar3 {
  width: 100%;
  height: 2px;
  background-color: #333;
  transform: translateY(24px);
}

.bar1-active {
  width: 100%;
  height: 2px;
  background-color: #333;
  transform: translateY(15px) rotate(-45deg);
  transition: transform 0.2s ease-in-out;
}

.bar2-active {
  width: 100%;
  height: 2px;
  background-color: #333;
  transform: translateY(14px) rotate(45deg);
  transition: transform 0.2s ease-in-out;
}

.bar3-active {
  display: none;
}

.menu-drawer {
  /* display: none; */
  transform-origin: top center;
  animation: showMenu 0.6s linear forwards;
}

@keyframes showMenu {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  /* background-color: #000000aa; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* In your CSS file or a <style> block */
.loader {
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.modal-content {
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #191a2a 0%, #12100e 100%);
  position: relative;
  max-width: 25rem;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 1em #000000aa;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 1.5px;
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #3FAAF0;
  border-radius: 5px;
}


.nav-logo-update {
  position: relative;
  top: -5px;
  width: 100px;
  /* height: fit-content; */
  /* max-width: 100px; */
  /* max-height: fit-content; */
  cursor: pointer;
}

.nav-logo-login {
  position: relative;
  top: -5px;
  width: 85px;
  height: fit-content;
  max-width: 100px;
  max-height: fit-content;
  cursor: pointer;
}


/* Service Cards */
.service-card .service-image {
  transition: transform 0.3s ease-in-out;
}

.service-card:hover .service-image {
  transform: scale(1.1);
}

.service-icon {
  color: #fff;
}

.service-card:hover .arrow {
  background: #fff;
}

.service-card:hover .service-icon {
  color: #000;
}

.black-overlay {
  background: linear-gradient(to bottom, #00000044, #000000b0);
}

/* Apply Page */
.apply-bg {
  height: 65vh;
  background: linear-gradient(to bottom, #00000059, #000000c0), url('../public/apply-bg.jpg') no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-bg {
  background: linear-gradient(to bottom, #000000a2, #000000e0), url('../public/text.jpg') no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.blur-bg {
  background: url('../public/blur-bg.png') no-repeat;
  background-size: cover;
}

/* Google Login */
.google-login-button {
  background-color: #fff !important;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  border: 1px solid #bbb !important;

}

.google-login-button:hover {
  background-color: #357ae8;
}

/* Preloader */
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-fade-in {
  animation: fadeIn 0.2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}